import './App.css';
import * as React from 'react';
import { Container, Box, Typography, Paper } from '@mui/material';
import { styled } from '@mui/system';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Helmet } from 'react-helmet';

const theme = createTheme({
  typography: {
    fontFamily: [
      'Share Tech Mono',
      'LogicLoop',
      'Roboto',
    ].join(','),
    h1: {
      fontWeight: 800,
      fontSize: 50,
      textShadow: 'none',
    },
    allVariants: {
      color: '#181716', // Replace with your desired color
      textShadow: '0px 20px 3px rgba(30, 15, 0, 0.2)',
      letterSpacing: '-0.08em',
    },
  }
});

const FullScreenContainer = styled(Container)(({ theme }) => ({
  height: '100vh',
  width: '100%',
  minWidth: '100vw',
  padding: 0,
  margin: 0,
  overflow: 'hidden',
  backgroundImage: `url(${process.env.PUBLIC_URL}/bgPatterns/pattern_2.png)`,
  backgroundSize: 'repeat',
  backgroundPosition: 'center',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  backgroundColor: 'rgba(0.3, 0.3, 0.3, 0)',
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
}));

const StyledText = styled(Typography)(({ theme }) => ({
  align:"center",
  textAlign:"justify",
  lineHeight:"2.5",
  fontSize:"20px",
}));

function App() {
  return (
    <>
    <Helmet>
      {/* Basic meta tags */}
      <title>Games For Good</title>
      <meta name="description" content="Dutch Applied Games Development | Making a positive impact through interactive experiences." />
      <meta name="keywords" content="Applied Games, Serious Games, Netherlands, Dutch, Game Developer, Game Studio, Serious Games, Training Games, Social Games, Game App" />

      {/* Tells Google not to show the sitelinks search box */}
      <meta name="google" content="nositelinkssearchbox" />

      {/* Tells Google that this is the version of the page they should index */}
      <link rel="canonical" href="gamesforgood.nl" />

      {/* Open Graph meta tags for social sharing */}
      <meta property="og:title" content="Games For Good" />
      <meta property="og:description" content="Making a positive impact through interactive experiences." />
      <meta property="og:type" content="website" />

      {/* Tells Google that they are allowed to index and follow links on the page */}
      <meta name="robots" content="index, follow" />
    </Helmet>

    <ThemeProvider theme={theme}>
        <FullScreenContainer>
            <Box textAlign="center">
              <Typography variant="h1">
                [Games For Good]
              </Typography>
              <Container sx={{width:'100%', mt:2}}>
                <StyledText>
                [Games For Good] is a Dutch game label dedicated to creating interactive media that focuses on bettering humans and their lives. 
                Our mission is to harness the power of fun to address real-world challenges, promote social norms, and inspire positive change. 
                Through carefully crafted experiences, we aim to educate, motivate, and empower players to make and be the difference in their communities and beyond. 
                Therefor, by bridging entertainment and impact [Games For Good] is focused on creating impactful sparks of joy, one game at a time.
                </StyledText>

                <StyledText sx={{textAlign:'center', mt:5}}>
                  For inquiries, please contact: pepndev@gmail.com
                </StyledText>
              </Container>
            </Box>
        </FullScreenContainer>
    </ThemeProvider>
    </>
  );
}

export default App;